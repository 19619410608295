<template>
  <Transition name="fade">
    <div v-show="state.showMobileMenu">
      <div class="mobilemenu">
        <NuxtLink to="/" class="mobilemenu-section" @click="toggleMobileMenu"
          >Home</NuxtLink
        >
        <!-- <NuxtLink
          to="/features"
          class="mobilemenu-section"
          @click="toggleMobileMenu"
          >Features</NuxtLink
        > -->
        <NuxtLink
          to="/security"
          class="mobilemenu-section"
          @click="toggleMobileMenu"
          >Security</NuxtLink
        >
        <NuxtLink
          to="/about"
          class="mobilemenu-section"
          @click="toggleMobileMenu"
          >About</NuxtLink
        >
        <NuxtLink
          to="/contact"
          class="mobilemenu-section"
          @click="toggleMobileMenu"
          >Contact</NuxtLink
        >
        <NuxtLink
          to="/blog"
          class="mobilemenu-section"
          @click="toggleMobileMenu"
          >Blog</NuxtLink
        >
        <!-- <NuxtLink
          to="/plans"
          class="mobilemenu-section"
          @click="toggleMobileMenu"
          >Plans</NuxtLink
        > -->
        <div class="mobilemenu-cta-section">
          <BtnCallToAction :fullSize="true" />
          <BtnCallToActionAlt :fullSize="true" />
        </div>
        <button class="mobilemenu-close-btn" @click="toggleMobileMenu">
          <div class="mobilemenu-close-btn-inner">
            <Icon name="fa6-solid:xmark" />
          </div>
        </button>
      </div>
      <div class="mobilemeny-click-to-dismiss" @click="toggleMobileMenu"></div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
const state = reactive({
  showMobileMenu: false,
});

/**
 * Toggle the mobile menu
 */
function toggleMobileMenu() {
  state.showMobileMenu = !state.showMobileMenu;
}

/** Expose the method to parent components */
defineExpose({
  toggleMobileMenu,
});
</script>

<style scoped>
.mobilemenu {
  position: fixed;
  top: 1rem;
  left: 5%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 90%;

  border-radius: var(--border-radius);
  border: 1px solid var(--seperator-colour);
  box-shadow: 0px 2px 5px 0px var(--shadow-colour);

  color: var(--text-foreground);
  background-color: var(--foreground);

  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

.mobilemenu-section {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  color: inherit;
}

.mobilemenu-cta-section {
  border-top: 1px solid var(--seperator-colour);
  background-color: var(--background);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  gap: 1rem;
  justify-content: center;
}

.mobilemenu-cta-section > a {
  background-color: var(--foreground);
  border-radius: var(--border-radius);
  flex: 1 0 auto;
}

.mobilemenu-close-btn {
  position: absolute;
  right: 0;
  top: 0%;
  display: flex;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.mobilemenu-close-btn-inner {
  display: flex;
  padding: 0.5rem;
  margin: 0.5rem;
  border-radius: var(--border-radius);

  border: 1px solid var(--seperator-colour);
}

.mobilemeny-click-to-dismiss {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; /* fallback for older browsers */
  height: 100dvh;

  /* limitation of css, cannot use on the fly opacity with hex */
  background-color: #21212180;
}
</style>
